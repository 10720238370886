* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --white: #fff;
  --main: #0a2342;
  --mainLight: #144785;
  --mainLightest: #cddcef;
  --text: #000205;
  --accent2: #eb5e55;
  --smallSpace: 5px;
  --medSpace: 10px;
  --bigSpace: 20px;
  --biggerSpace: 40px;
  font-size: 20px;
}

body {
  margin: 0;
  font-family: 'Roboto', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--white);
  color: var(--text);
  font-size: 1rem;
  height: 100vh;
}

.bold {
  font-weight: bold;
}

#page {
  align-items: stretch;
  height: 100vh;
  width: 100vw;
  grid-template-columns: minmax(300px, 425px) minmax(600px, auto);
  overflow: hidden;
}

.headshot {
  border-radius: 50%;
}

ul {
  list-style-type: none;
}

/* headings */
h1, h2, h3, h4 {
  font-family: 'Averia Serif Libre', sans-serif;
  font-weight: 600;
}

h1 {
  font-size: 2.75rem;
}

h2 {
  font-size: 2.5rem;
}

h3 {
  font-size: 2rem;
}

h4 {
  border-bottom: 3px solid var(--accent2);
  padding-bottom: 3px;
  font-size: 1.5rem;
  color: var(--text);
  margin: var(--biggerSpace) 0 var(--bigSpace) 0;
}

h4:first-child {
  margin-top: 0;
}

/* end of headings */

/* text */
p {
  text-indent: 0;
}

span {
  color: var(--mainLight);
  font-weight: bold;
  margin-right: var(--smallSpace);
}

.authors {
  font-weight: 300;
}

/* end of text */

/* links and buttons */

.link {
  text-decoration-color: transparent;
  color: var(--mainLight);
}

.link-white {
  text-decoration: underline dashed 1px var(--mainLightest);
  text-underline-offset: 3px;
  opacity: 90%;
  color: var(--white);
}

.link-white:hover {
  /* text-decoration-color: var(--mainLightest); */
  opacity: 100%;
  transition: opacity 0.3s ease-in;
}

.link:hover {
  text-decoration-color: var(--mainLight);
  transition: text-decoration-color 0.3s ease-in;
}

.btn {
  background: none;
  border: none;
  padding: 0;
  color: var(--mainLight);
  font-family: 'Roboto', serif;
  font-size: 1rem;
}

.btn:hover {
  cursor: pointer;
}

.link:visited {
  color: var(--mainLight);
}

.icon-link, .icon-link:visited {
  color: inherit;
  text-decoration: none;
}

/* end of links */

/* sidebar/header */

header {
  background: var(--main);
  color: var(--white);
  padding: 30px;
  align-items: flex-end;
}

.contact li:nth-child(n+1) {
  margin-left: var(--medSpace);
}

.contact-icon {
  width: 1.5em;
  height: 1.5em;
  opacity: 80%;
}

.contact-icon:hover {
  opacity: 100%;
  cursor: pointer;
  transition: opacity 0.3s ease-in;
}

.about {
  text-align: right;
}

.mobile-nav:hover {
  cursor: pointer;
}

/* end of sidebar/header */

/* nav */

.nav-link {
  border-bottom: 5px solid transparent;
}

.nav-link:hover, .nav-active {
  border-bottom-color: var(--accent2);
  cursor: pointer;
  transition: border-bottom-color 0.3s ease-in;
}

.nav-link:nth-child(n+1) {
  margin: 0 var(--medSpace);
}

/* end of sidebar/header */

/* main */

main {
  padding: var(--bigSpace);
  align-self: start;
}

section {
  max-height: 90vh;
  overflow-y: auto;
  padding: var(--medSpace);
}

/* end of main */

/* papers */
.abstract-text {
  font-style: italic;
  font-weight: 300;
  max-width: 80%;
  margin: var(--smallSpace) 0 0 var(--bigSpace);
  padding: var(--medSpace) var(--bigSpace);
  border-left: 2px solid var(--main);
}

.citation-text {
  background: var(--mainLightest);
  max-width: 80%;
  margin: var(--medSpace) 0 0 var(--bigSpace);
  white-space: pre-wrap;
  display: block;
  padding: var(--bigSpace);
}

.btn:hover > .btn-inner:hover {
  transform: translateX(5px);
  transition: transform 0.3s ease-in-out;
}
/* end of papers */

/* list group */
.list-group {
  margin-bottom: 15px;
}

.list-el {
  margin-bottom: var(--smallSpace);
}

/* end of list group */

/* utility */
.grid {
  display: grid;
}

.flex {
  display: flex;
}

.row {
  flex-flow: row wrap;
}

.row-reverse {
  flex-flow: row wrap-reverse;
}

.column {
  flex-flow: column wrap;
}

.align-baseline {
  align-items: baseline;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-c-start {
  align-content: flex-start;
}

.align-c-between {
  align-content: space-between;
}

.justify-between {
  justify-content: space-between;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-end, .justify-end-lg {
  justify-content: flex-end;
}

.pb-1 {
  padding-bottom: var(--smallSpace);
}

.mt-1 {
  margin-top: var(--smallSpace);
}

.mt-2 {
  margin-top: var(--medSpace);
}

.mt-3 {
  margin-top: var(--bigSpace);
}

.mb-1 {
  margin-bottom: var(--smallSpace);
}

.mb-2 {
  margin-bottom: var(--medSpace);
}

.mb-3 {
  margin-bottom: var(--bigSpace);
}

.mr-1 {
  margin-right: var(--smallSpace);
}

.mr-2 {
  margin-right: var(--medSpace);
}

.mr-3 {
  margin-right: var(--bigSpace);
}

.ml-1 {
  margin-left: var(--smallSpace);
}

.ml-2 {
  margin-left: var(--medSpace);
}

.ml-3 {
  margin-left: var(--bigSpace);
}

.d-inl {
  display: inline;
}

.d-inl-bl {
  display: inline-block;
}

.w-100 {
  width: 100%;
}

.show-mobile {
  display: none;
}

.text-mobile {
  font-size: 0.75rem;
}
/* end of utility */

/* media queries */
@media only screen and (max-width: 1600px) {
  :root {
    font-size: 16px;
  }
}

@media only screen and (max-width: 992px) {
  .hide-mobile {
    display: none;
  }
  .show-mobile {
    display: flex;
  }
  #page {
    display: flex;
    flex-flow: column wrap;
    height: unset;
    width: unset;
    overflow: auto;
  }
  section {
    max-height: unset;
  }
  .justify-start-md {
    justify-content: flex-start;
  }
  header {
    justify-content: flex-start;
  }
  header, main {
    min-width: 100%;
  }
  .abstract-text, .citation-text {
    max-width: 100%;
    margin-left: 0;
  }
  header {
    align-items: center;
  }
  .about {
    text-align: center;
  }
}

@media only screen and (max-width: 576px) {
  :root {
    font-size: 12px;
  }
  h1 {
    font-size: 2.25rem;
  }
  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1.5rem;
  }
  h4 {
    font-size: 1.5rem;
  }
  .justify-center-sm {
    justify-content: center;
  }
}
/* end of media queries */